import styled from 'styled-components';
import AppColors from '../../../styles/colors';

export const BodyContainer = styled.div`
  margin-top: -1px;
  background: ${AppColors.White};
`;

export const Body = styled.div`
  margin: 0px auto;
  padding-bottom: 0px;
  background-color: #fff;
  display: block;
  @media (max-width: 991px) {
    padding: 0 !important;
  }
  @media (max-width: 767px) {
    padding: 0 !important;
  }
  overflow: hidden;
`;
export const BodyContainerFirst = styled(BodyContainer)`
  ${Body} {
    padding-bottom: 50px;
  }
`;

export const ImageStyleBlockRight = styled.img`
    display: block;
    height: auto !important;
    float: right;
  @media (max-width: 991px) {
    display: block;
    float: right;
    margin: 0 0 40px 0px;
  }
  @media (max-width: 767px) {
    display: block;
    float: none;
    margin: 0 0 25px 0;
    width: 100%;
  }
`;
export const LinkStyle = styled.a`
  font-family: 'HelveticaNeue-Light', arial, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  color: #55aa4c;
  transition: 0.25s ease-out;
  :hover {
    color: #00a6e8;
  }
`;
export const Header4 = styled.h4`
  overflow: hidden;
  padding: 0 0 15px 0;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: normal;
  @media (max-width: 1439px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const ParaText = styled.p`
  font-family: 'Gotham-Light', arial, sans-serif;
  font-size: 24px;
  line-height: 38px;
  width: 65%;
  :last-child {
    margin: 0px;
  }
  color: rgba(0, 0, 0, 0.8);
  i {
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 991px) {
      font-size: 15px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
  }
  code {
    font-family: 'HelveticaNeue-Light', arial, sans-serif;
    font-size: 20px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.6);
  }
  a > code {
    display: inline-block;
    position: relative;
    top: 0;
    padding: 17px 35px 13px 35px;
    margin: 0 5px 5px 0;
    font-family: 'Adelle-Bold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #55aa4c;
    border: 2px solid #55aa4c;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
    border-radius: 100px;
    cursor: pointer;
    @media (max-width: 767px) {
      padding: 10px 20px 6px 20px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 10px;
    }
    :hover {
      top: -5px;
      color: #55aa4c;
      border-color: #55aa4c;
      box-shadow: 0 5px 0 0 #55aa4c;
    }
  }
  @media (max-width: 1399px) {
    font-size: 16px;
    margin: 0 0 30px 0;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    font-size: 19px !important;
    line-height: 27px !important;
    margin: 0 0 30px 0;
    width: 100%;
  }
`;
export const ListContainer = styled.ul`
  padding: 0;
  margin: 0 0 30px 0;
`;
export const ListText = styled.li`
  padding: 0 0 15px 30px;
  background-size: 10px auto;
  list-style: none;
  margin: 0;
  ${ParaText} {
    margin: 0;
  }
  @media (max-width: 1399px) {
    background-position: 0 9px;
  }
  @media (max-width: 767px) {
    background-position: 0 5px;
  }
`;
export const HeaderH3 = styled.h3`
  display: none;
  position: relative;
  margin-right: 0px;
  margin-bottom: 40px;
  margin-left: 0px;
  padding: 0px 0px 20px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 42px;
  line-height: 45px;
  color: rgb(31, 178, 90);
  @media (max-width: 1399px) {
    margin-top: 0px;
  }
  @media (max-width: 767px) {
    display : block;
    margin: 0px 0px 30px;
    font-size: 25px;
    line-height: 27px;
  }
  &.lessMargin {
    margin-bottom: 15px;
  }
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 4px;
    transition: all 0.2s ease-out;
    background: ${AppColors.backgroundBlack1};
  }
`;
export const HeaderH2 = styled.h2`
  position: relative;
  margin-right: 0px;
  margin-bottom: 40px;
  margin-left: 0px;
  padding: 0px 0px 20px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 42px;
  line-height: 45px;
  color: rgb(31, 178, 90);
  @media (max-width: 1399px) {
    margin-top: 0px;
  }
  @media (max-width: 767px) {
    display: none;
    margin: 0px 0px 30px;
    font-size: 25px;
    line-height: 27px;
  }
  &.lessMargin {
    margin-bottom: 15px;
  }
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 4px;
    transition: all 0.2s ease-out;
    background: ${AppColors.backgroundBlack1};
  }
`;
export const ItalicParaText = styled(ParaText)`
  font-style: italic;
`;
export const AnchorButton = styled.a`
  padding: 16px 85px 14px 15px;
  margin: 0 5px 30px 0;
  text-decoration: none;
  font-family: 'Gotham-Medium', arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase !important;
  text-align: left;
  color: #037ec4;
  background-size: 12px auto;
  &:hover {
    background-size: 12px auto;
    transition: background-position 0.15s ease-out;
    border: none;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;
export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin: 40px 0 40px 0;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #969696;
`;
export const Header = styled.h1`
  font-family: 'MrAlex', sans-serif;
  font-weight: normal !important;
  color: #00539d;
  font-size: 45px;
  line-height: 50px;
  margin-bottom: 10px;
  @media (max-width: 1399px) {
    font-size: 40px;
    line-height: 45px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const HeaderH6 = styled.h6`
  font-family: 'Gotham-Regular', arial, sans-serif !important;
  font-size: 17px !important;
  line-height: 32px !important;
  color: rgba(0, 0, 0, 0.8) !important;
  margin-bottom: 40px !important;
  @media (max-width: 767px){
    margin: 0 0 30;
    padding: 0 !important;
    font-size: 15px !important;
    line-height: 23px !important;
  }  
`;
export const SpanStyle = styled.span`
  position: relative;
  top: -200px;
  @media (max-width: 991px) {
    top: -50px;
  }
`;
export const HorizontalBreak = styled.hr`
  height: 2px !important;
  border: none;
  background: rgba(0, 0, 0, 0.8);
  margin: 40px 0;
  clear: both;
  opacity: 1;
  @media (max-width: 1399px) {
    margin: 35px 0;
  }
  @media (max-width: 991px) {
    margin: 60px 0;
  }
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;

export const BlockQuotes = styled.blockquote`
  position: relative;
  width: 35%;
  margin: 0 0 80px 0;
  float: right;
  padding: 40px;
  margin: 0px 0px 40px;
  background: rgba(0, 0, 0, 0.05);
  @media (max-width: 991px) {
    width: 100%;
    margin: 0px 0px 40px;
  }
  @media (max-width: 767px) {
    padding: 30px;
    margin: 0 0 30px 0;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 18px 0 0px;
    border-color: ${AppColors.backgroundBlack} transparent transparent
      transparent;
  }
  p {
    font-family: 'Gotham-Light', arial, sans-serif;
    font-size: 24px;
    line-height: 38px;
    -webkit-box-pack: center;
    width: 100%;
    margin-bottom: 0px;
    @media (max-width: 1399px) {
      margin-bottom: 30px;
    }
    i {
      font-family: 'Gotham-Regular', arial, sans-serif !important;
      font-size: 17px !important;
      line-height: 32px !important;
      color: rgba(0, 0, 0, 0.8) !important;
      width: 100%;
      @media (max-width: 1399px) {
        font-size: 15px;
        line-height: 22px;
      }
      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
  h3 {
    width: 100%;
    position: relative;
    margin-bottom: 0px;
    font-family: interstate, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 35px;
    line-height: 40px;
    color: rgb(0, 105, 170);
    text-align: left;
    @media (max-width: 767px) {
      margin: 0px 0px 30px;
      font-size: 20px;
      line-height: 22px;
    }
  }
`;

export const BlockQuotesLinkStyle = styled.blockquote`
  position: relative;
  margin: 0 0 0 0;
  padding: 0 0 20px;
  font-family: interstate, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 42px;
  line-height: 45px;
  margin-top: 0 !important;
  color: ${AppColors.LightGreen};
  &.lessMargin {
    margin-bottom: 15px;
  }
  ::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 4px;
    transition: all 0.2s ease-out;
    background: ${AppColors.backgroundBlack1};
  }
  @media (max-width: 1399px) {
    margin-top: 0 !important;
  }
  @media (max-width: 767px) {
    margin: 0 0 30px 0;
    font-size: 25px;
    line-height: 27px;
  }
  :hover {
    color: #55aa4c;
  }
`;
