import * as Styled from './richTextWithImageRightStyles';
import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import {useContentfulAssets} from '../../../hooks/useContentfulAssets';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../../utils';
import {  HeaderH3, HorizontalBreak } from '../../../styles';

const ContentWithImageRight = ({ sectionData, location }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.Header>{children}</Styled.Header>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Styled.HeaderH6>{children}</Styled.HeaderH6>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.ListContainer>{children}</Styled.ListContainer>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListText>{children}</Styled.ListText>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              : addTrailingSlash(
                 process.env.GATSBY_DOMAIN_URL + node?.data?.uri
              )
          }
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <Styled.BlockQuotes>{children}</Styled.BlockQuotes>
      ),
      [MARKS.ITALIC]: (node, children) => (
        <Styled.ItalicParaText>{children}</Styled.ItalicParaText>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return (
          <Styled.LinkStyle href={asset?.node?.file?.url} target="_blank">
            {children}
          </Styled.LinkStyle>
        );
      },

      [BLOCKS.HR]: () => <Styled.HorizontalBreak />,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node.data.target.sys.id);
        if (asset) {
          return (
            <Styled.ImageStyleBlockRight
              src={asset.node.file.url}
              alt={asset.node.title}
            />
          );
        }
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };
  React.useEffect(async () => {
    if (sectionData?.elementId === location?.hash?.replace('#', '')) {
      const waitedID = await new Promise((resolve) => {
        document?.getElementById(sectionData?.elementId) && resolve(true);
      });
      if (waitedID) {
        setTimeout(() => {
          window.location.href = `#${sectionData?.elementId}`;
        }, 500);
      }
    }
  }, [sectionData?.elementId]);
  return (
    <Styled.Body>
      <Styled.SpanStyle id={sectionData?.elementId}></Styled.SpanStyle>
      {sectionData.header && sectionData?.header?.trim() && (
        <Styled.HeaderH2 className="lessMargin">
          {sectionData.header}
        </Styled.HeaderH2>
      )}
      {sectionData?.subText?.trim() && (
        <Styled.HeaderH3 className="lessMargin">
          {sectionData?.subText}
        </Styled.HeaderH3>
      )}
      {sectionData.contentDetails &&
        documentToReactComponents(
          JSON.parse(sectionData.contentDetails.raw),
          optionsMainStyle
        )}
    </Styled.Body>
  );
};
export default ContentWithImageRight;
